@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/reset.css';


.ant-modal-cus{
  max-width: 400px !important;
  top: 20px !important;
  min-height: 90vh !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ant-btn-primary{
  background: #1677ff !important;
}

label{
  font-weight: 500;
  color: grey;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}